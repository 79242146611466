<template>
  <FormSelect
    label="Nivel de tráfico"
    placeholder="Nivel de tráfico"
    :options="options"
    v-bind="$attrs"
  />
</template>

<script>
import FormSelect from './forms/FormSelect'

export default {
  name: 'WorkLevelDropdown',
  data() {
    const options = [
      {id: 'bajo', label: 'Bajo'},
      {id: 'medio', label: 'Medio'},
      {id: 'alto', label: 'Alto'},
    ]

    return { options }
  },
  components: {
    FormSelect,
  }
}
</script>
