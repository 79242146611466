<template>
  <form
    novalidate
    @submit.prevent="onSubmit"
  >
    <fieldset>
      <legend ref="header">Actualización de contraseña</legend>

      <BooleanCheckbox
          label="¿Asignar la contraseña por defecto PrimaxUser*?"
          v-model="useDefaultPassword"
          class="mb-3"
      />

      <div :style="{
        opacity: useDefaultPassword ? 0.5 : 1,
        'pointer-events': useDefaultPassword ? 'none' : 'auto',
      }">
        <FormInput
            type="password"
            label="Nueva contraseña"
            v-model="newPassword"
            placeholder="super-scret"
            :error="errors.newPassword"
            />

        <FormInput
            type="password"
            label="Confirme la contraseña"
            v-model="passwordConfirmation"
            placeholder="super-scret again"
            :error="errors.passwordConfirmation"
            />
      </div>
    </fieldset>

    <Button
        type="submit"
        status="info"
        :isLoading="isLoading"
        class="mt-3"
        >
        Actualizar
    </Button>
  </form>
</template>

<script>
  import Button from '../Button'
  import FormInput from '../forms/FormInput'
  import BooleanCheckbox from '../BooleanCheckbox'

  export default {
    name: 'CreateUserForm',

    props: [
      'isLoading',
      'serverError',
      'defaultValues',
    ],

    expose: ['scrollToTop'],

    emits: ['submit'],

    watch: {
      serverError(err) {
        err.fields.forEach(err => {
          this.errors[err.field] = err
        })
      },
    },

    data() {
      return {
        errors: {},
        useDefaultPassword: true,
        ...this.initialState(),
      };
    },

    methods: {
      scrollToTop() {
        this.$refs.header.scrollIntoView()
      },
      initialState() {
        return {
          errors: {},
          newPassword: '',
          passwordConfirmation: '',
          useDefaultPassword: true,
        }
      },

      async onSubmit() {
        if (this.useDefaultPassword) {
          this.$emit(
            'submit',
            {password: 'PrimaxUser*'},
            () => this.reset(),
          )
          return
        }

        if (!this.newPassword) {
          this.errors = {
            newPassword: {
              message: 'La contraseña es obligatoria.',
            },
          }
          return
        }

        if (this.newPassword !== this.passwordConfirmation) {
          this.errors = {
            passwordConfirmation: {
              message: 'La contraseña no coincide.'
            },
          }
          return
        }

        this.errors = {}

        this.$emit(
          'submit',
          {password: this.newPassword},
          () => this.reset(),
        )
      },

      reset() {
        Object.assign(this.$data, this.initialState());
      },
    },

    components: {
      FormInput,
      Button,
      BooleanCheckbox,
    }
  }
</script>

<style>
</style>
