<template>
  <Drawer
      :isOpen="isOpen"
      title="Actualizar una estación"
      @close="$emit('close')"
      >
      <StationForm
          :key="station ? station.id : null"
          ref="stationForm"
          :defaultValues="formValues"
          :isLoading="isLoading"
          :serverError="serverError"
          @submit="updateStation"
          />
  </Drawer>
</template>

<script>
  import StationForm from './StationForm'
  import Drawer from '../Drawer'
  import stationService from '../../services/stationService'

  export default {
    name: 'UpdateStationDrawer',
    props: [
      'isOpen',
      'station',
    ],
    emits: [
      'close',
      'updated',
    ],
    watch: { 
      station(newStation) {
        if (!newStation) {
          this.formValues = {}
          return;
        }

        this.formValues = {
          pbl: newStation.pbl,
          serviceStation: newStation.service_station,
          address: newStation.address || '',
          companyName: newStation.companyName || '',
          nit: newStation.nit,
          pco: newStation.pco || '',
          names: newStation.names || '',
          emails: newStation.emails || '',
          phone: newStation.phone || '',
          clientType: newStation.clients_type || '',
          stationType: newStation.station_type || '',
          location: {
            state: newStation.department,
            city: newStation.city,
          },
        }
      },
    },
    data() {
      return {
        serverError: null,
        isLoading: false,
        formValues: {},
      }
    },
    methods: {
      async updateStation(newStation) {

        this.isLoading = true

        const payload = {
          id: this.station.id,
          pbl: newStation.pbl,
          serviceStation: newStation.serviceStation,
          pco: newStation.pco,
          address: newStation.address,
          phone: newStation.phone,
          names: newStation.names,
          emails: newStation.emails,
          companyName: newStation.companyName,
          nit: newStation.nit,
          clientsType: newStation.clientType,
          stationType: newStation.stationType,
        }

        if (newStation.location.city) {
          payload.city = newStation.location.city
        }

        if (newStation.location.state) {
          payload.department = newStation.location.state
        }

        try {
          const station = await stationService.update(payload)
          this.$emit('updated', station)
        } catch (e) {
          if (e.name !== 'APIError') {
            throw e
          }

          // Scroll to the top of the form, client need to check the which field has an error.
          this.$refs.stationForm.scrollToTop()

          this.serverError = e
        } finally {
          this.isLoading = false
        }
      }
    },

    components: {
      StationForm,
      Drawer,
    }
  }
</script>
