<template>
  <div class="form-group">
    <label>{{label}}</label>
    <Multiselect
      :options="roles"
      track-by="id"
      label="label"
      :searchable="false"
      v-bind="$attrs"
      :modelValue="selectedValue"
      @update:modelValue="onSelected"
    ></Multiselect>
    <div v-if="!!error" :class="{'invalid-feedback': !!error}">
      {{error.message}}
    </div>
  </div>
</template>

<script>
import Multiselect from '../Multiselect'
import { roles } from '../../lib/roles'

export default {
  name: 'RoleDropdown',
  emits: ['update:modelValue'],
  props: [
    'label',
    'error',
    'modelValue'
  ],
  data() {
    return {
      roles,
    }
  },
  methods: {
    onSelected(v) {
      this.$emit('update:modelValue', v.id)
    },
  },
  computed: {
    selectedValue() {
      return this.roles.find(role => this.modelValue === role.id)
    },
  },
  components: {
    Multiselect,
  }
}
</script>
