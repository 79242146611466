<template>
  <FormSelect
      label="Tipo de estación"
      :placeholder="loading ? 'Cargando' : 'Tipo de estación'"
      :options="types"
  />
</template>

<script>
import userService from '../../services/userService'
import FormSelect from './FormSelect'

export default {
  name: 'StationTypesDropdown',
  data() {
    return {
      loading: true,
      types: [],
    }
  },
  methods: {
  },
  async mounted() {
    try {
      const types = await userService.getUserTypes()
      this.types = types.map(type => {
        return {
          id: type.id,
          label: type.name,
        }
      })
    } finally {
      this.loading = false
    }
  },
  components: {
    FormSelect,
  }
}
</script>
