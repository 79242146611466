<template>
  <div class="form-group">
    <label>{{label}}</label>
    <DatePicker v-bind="$attrs"></DatePicker>
    <div v-if="!!error" :class="{'invalid-feedback': !!error}">
      {{error.message}}
    </div>
  </div>
</template>

<script>
import DatePicker from './DatePicker'

export default {
  name: 'FormDatePicker',
  props: [
    'label',
    'error',
  ],
  components: {
    DatePicker,
  }
}
</script>
