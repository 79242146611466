<template>
  <div
    class="drawer-overlay"
    :class="{open: isOpen}"
    @click="overlayClicked"
  />

  <div
    class="drawer"
    :class="{open: isOpen}"
  >
    <div class="title-container">
      <h3>{{title}}</h3>
      <button
        @click="closeButtonClicked"
        class="unstyled"
      >
        <i class="fas fa-times"/>
      </button>
    </div>
    <hr />
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Drawer',
  props: ['title', 'isOpen'],
  emits: ['close'],
  methods: {
    overlayClicked() {
      this.closeRequest();
    },
    closeButtonClicked() {
      this.closeRequest();
    },
    closeRequest() {
      this.$emit('close')
    },
  },
}
</script>

<style>
.drawer-overlay {
  background-color: black;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1039;
  opacity: 0.8;
  display: none;
}

.drawer {
  display: none;
  position: fixed;
  background: white;
  right: 0;
  width: 50%;
  height: 100vh;
  top: 0;
  z-index: 1040;
  padding: 1rem;
  overflow: auto;
}


.drawer-overlay.open, .drawer.open {
  display: block;
}

.title-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
