<template>
  <div class="modal-body">
    <slot />
  </div>
</template>

<script>
  export default {
    name: 'ModalBody',
  }
</script>
