<template>
  <FormSelect
    name="state"
    label="Departamento"
    placeholder="Departamento"
    :options="locations"
    :value="value ? value.state : null"
    @change="stateChanged"
  />

  <FormSelect
    name="city"
    label="Ciudad/Municipio"
    placeholder="Ciudad/Municipio"
    :value="value ? value.city : null"
    :options="cities"
    @change="cityChanged"
  />
</template>

<script>
import FormSelect from './FormSelect';
import locations from '../../assets/cities/colombia.min.json'

export default {
  name: 'LocationDropdown',

  emits: ['change'],

  props: [
    'label',
    'value',
  ],

  data() {
    return {
      locations: [],
      cities: [],
    }
  },

  watch: {
    value() {
      this.updateAvailableCities()
    },
  },

  methods: {
    updateAvailableCities() {
      if (!this.value) {
        this.cities = []
        return;
      }

      const currentState = this.locations.find(state => state.id === this.value.state);
      if (currentState) {
        this.cities = currentState.cities
      } else {
        this.cities = []
      }
    },

    stateChanged(value) {
      this.$emit('change', {
        state: value,
        city: null,
      })
    },

    cityChanged(value) {
      if (!this.value) {
        return
      }

      this.$emit('change', {
        state: this.value.state,
        city: value,
      })
    },
  },

  // Create a normalized version of the locations. Currently it looks like:
  //   [
  //     {
  //       id: 0,
  //       departamento: "Amazonas",
  //       ciudades: [
  //         "Leticia",
  //         ...
  //       ]
  //     }
  //   ]
  // Now it will looks like:
  //   [
  //     {
  //       id: Amazonas,
  //       label: "Amazonas",
  //       cities: [
  //         {
  //           "id": "Leticia"
  //           "label": "Leticia"
  //         }
  //       ]
  //     }
  //   ]
  mounted() {
    const normalizedLocations = locations.map(location => {
      return {
        id: location.departamento,
        label: location.departamento,
        cities: location.ciudades.map(city => {
          return {
            id: city,
            label: city,
          }
        })
      }
    })

    this.locations = normalizedLocations;
    this.updateAvailableCities()
  },

  components: {
    FormSelect,
  }
}
</script>
