<template>
  <div class="form-group">
    <label>{{label}}</label>
    <select
      class="custom-select"
      v-bind="$attrs"
      @change="selected"
    >
      <option v-if="placeholder" value="">{{placeholder}}</option>
      <option
        v-for="option of options"
        :key="option.id"
        :value="option.id"
        :selected="isSelected(option)"
      >
        {{option.label}}
      </option>
    </select>
    <div v-if="!!error" :class="{'invalid-feedback': !!error}">
      {{error.message}}
    </div>
  </div>
</template>

<script>
export default {
  name: 'FormSelect',
  emits: [
    'change',
    'update:modelValue',
  ],
  methods: {
    selected(event) {
      const value = event.target.value

      this.$emit('change', value)
      this.$emit('update:modelValue', value)
    },

    isSelected(option) {
      if (this.modelValue) {
        return this.modelValue.toString() === option.id.toString()
      }

      return !!this.value && this.value.toString() === option.id.toString()
    },
  },
  props: [
    'label',
    'options',
    'placeholder',
    'value',
    'modelValue',
    'error',
  ],
}
</script>
