<template>
  <Drawer
    :isOpen="isOpen"
    title="Estaciones asociadas"
    @close="$emit('close')"
  >
    <div class="row align-items-center">
      <SearchForm
        :search="search"
        :justifyStart="true"
        class="col-md-4"
        @change="searchChanged"
      />
      <div class="col-md-6">
        <BooleanCheckbox
          label="Mostrar solo estaciones asociadas"
          v-model="onlyAssociated"
        />
      </div>
    </div>

    <hr />
    <ul class="p-0 mt-3">
      <li
        class="p-1 d-flex justify-content-between"
        :key="station.id"
        v-for="station of stations.data"
      >
        <span>
          <span class="low-contrast mr-2">{{station.pbl}}</span>
          {{station.service_station}}
        </span>
        <Button
          :status="station.associated ? 'danger' : 'success'"
          :outline="true"
          :isLoading="loading"
          @click="onStationClicked(station)"
        >
          {{station.associated ? 'Quitar' : 'Asociar'}}
        </Button>
      </li>
    </ul>

    <hr />

    <Pagination
        :pagination="stations"
        @set-page="setPage"
        />
  </Drawer>
</template>

<script>
import Drawer from '../Drawer'
import Pagination from "../Pagination";
import SearchForm from '../SearchForm'
import BooleanCheckbox from '../BooleanCheckbox'
import Button from '../Button'
import stationService from '../../services/stationService'

export default {
  name: 'UserStationsDrawer',
  props: ['user'],
  watch: {
    async user(newUser) {
      if (!newUser) {
        return
      }

      await this.fetchStationsWithCurrentOptions()
    },
    async onlyAssociated() {
      this.page = 1
      await this.fetchStationsWithCurrentOptions()
    },
  },
  data() {
    return {
      page: 1,
      pageSize: 10,
      search: '',
      loading: false,
      onlyAssociated: false,
      stations: {
        data: [],
      },
    }
  },
  methods: {
    async setPage(page) {
      this.page = page

      await this.fetchStationsWithCurrentOptions()
    },

    async searchChanged(search) {
        this.page = 1
        this.search = search
        await this.fetchStationsWithCurrentOptions()
    },

    async onStationClicked(station) {
      this.loading = true

      try {
        if (station.associated) {
          await this.removeRelation(station)
        } else {
          await this.associate(station)
        }

        await this.fetchStationsWithCurrentOptions()
      } finally {
        this.loading = false
      }
    },

    async associate(station) {
      await stationService.associateStationTo(station.id, this.user.id)
      await this.fetchStationsWithCurrentOptions()
    },

    async removeRelation(station) {
      await stationService.removeRelation(station.id, this.user.id)
      await this.fetchStationsWithCurrentOptions()
    },

    async fetchStationsWithCurrentOptions() {
      this.stations = await stationService.getPaginatedStationsWithoutUsers({
        page: this.page,
        user: this.user,
        search: this.search,
        pageSize: this.pageSize,
        onlyAssociated: this.onlyAssociated,
      })
    }
  },
  components: {
    BooleanCheckbox,
    Drawer,
    Pagination,
    Button,
    SearchForm,
  }
}
</script>
