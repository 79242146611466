<template>
  <FormSelect
    label="Sexo"
    placeholder="Género"
    :options="options"
    v-bind="$attrs"
  />
</template>

<script>
import FormSelect from './forms/FormSelect'

export default {
  name: 'GenderDropdown',
  data() {
    return {
      options: [
        {id: 'male', label: 'Masculino'},
        {id: 'female', label: 'Femenino'},
        {id: 'other', label: 'Otro'},
      ],
    }
  },
  components: {
    FormSelect,
  }
}
</script>
