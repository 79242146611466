<template>
  <div class="form-group">
    <label>{{label}}</label>
    <input
      class="form-control"
      :class="{'is-invalid': !!error}"
      v-bind="$attrs"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
    />
    <div v-if="!!error" :class="{'invalid-feedback': !!error}">
      {{error.message}}
    </div>
  </div>
</template>

<script>
export default {
  name: 'FormInput',
  props: [
    'label',
    'error',
    'modelValue',
  ],
}
</script>
