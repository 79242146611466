<template>
  <VueMultiselect
    selectLabel="Presiona enter para seleccionar"
    deselectLabel="Presiona enter para eliminar"
    placeholder="Selecciona"
    selectedLabel="Seleccionado"
    v-bind="$props"
  >
    <template v-slot:noOptions>
      La lista está vacia.
    </template>
    <template v-slot:noResult>
      No se encontraron elementos, modifica la busqueda
    </template>
  </vueMultiselect>
</template>

<script>
  import VueMultiselect from 'vue-multiselect'

  export default {
    name: 'Multiselect',
    components: {
      VueMultiselect,
    }
  }
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<style>
.multiselect__tag {
  background: #FF6900;
}
.multiselect__tag-icon::after {
  color: #953D00;
}
.multiselect__option--highlight,
.multiselect__option--highlight::after {
  background: #FF6900;
}
</style>
