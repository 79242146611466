<template>
  <div>
    <div class="form-check form-switch">
      <input
        :id="publicID"
        class="form-check-input"
        type="checkbox"
        :checked="!!modelValue"
        @input="changed"
      />
      <label class="form-check-label" :for="publicID">
        {{label}}
      </label>
    </div> 
    <div v-if="!!error" :class="{'invalid-feedback': !!error}">
      {{error.message}}
    </div>
  </div>
</template>

<script>
export default {
  name: 'BooleanCheckbox',
  emits: ['update:modelValue'],
  props: [
    'label',
    'error',
    'modelValue',
  ],
  data() {
    return {
      id: Math.random() * 100000,
    }
  },
  methods: {
    changed(e) {
      this.$emit('update:modelValue', e.target.checked)
    }
  },
  computed: {
    publicID() {
      return `switch-${this.id}`
    },
  },
  components: {
  }
}
</script>
