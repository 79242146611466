<template>
  <LayoutDefault>
    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
      <Toast
        :message="toastMessage"
        v-model:active="toastActive"
        :type="toastType"
      />
      <Loading :active="loading" />
      <!-- Content Header (Page header) -->
      <div class="content-header mt-2">
        <div class="container-fluid">
          <Heading title="Usuarios">
              <button
                type="button"
                class="btn btn-default btn-default-invert float-right ml-2 mb-2 mb-md-0"
                @click="createUserDrawerOpen = true"
              >
                Crear
              </button>
          </Heading>
          <div class="row">
            <PageSizeSelect
                class="col-xs-12 col-sm-3"
                :size="pageSize"
                @change="setPageSize"
                />

            <SearchForm
                :value="search"
                @change="searchChanged"
                />
            <!-- /.col -->
          </div>
        </div>
        <!-- /.container-fluid -->
      </div>
      <!-- /.content-header -->

      <!-- Main content -->
      <section class="content">
        <div class="container-fluid">
          <div class="card card--table">
            <!-- <div class="card-header">
      
            </div> -->
            <!-- /.card-header -->
            <div class="card-body">
              <table class="table table-bordered table-striped">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Nombre</th>
                    <th>Email</th>
                    <th>Teléfono</th>
                    <th>Rol</th>
                    <th>Opciones</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="user of pagination.data" :key="user.id">
                    <td class="low-contrast">{{ user.id }}</td>
                    <td>{{ user.lastName }} {{ user.name }}</td>
                    <td>{{ user.email }}</td>
                    <td class="low-contrast">{{ user.phone }}</td>
                    <td class="low-contrast">{{ roleName(user.role) }}</td>
                    <td>
                      <button
                        class="unstyled ml-3"
                        aria-label="Actualizar"
                        @click="userToUpdate = user"
                      >
                        <i class="fas fa-pen"></i>
                      </button>
                      <button
                        class="unstyled"
                        aria-label="Eliminar"
                        @click="userToDelete = user"
                      >
                        <i class="fas fa-trash"></i>
                      </button>
                      <button
                        class="unstyled"
                        aria-label="Estaciones"
                        @click="updateStationsFor = user"
                      >
                        <i class="fas fa-building"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>

              <Pagination
                  :pagination="pagination"
                  @set-page="setPage"
                  />
            </div>
            <!-- /.card-body -->
          </div>
        </div>
        <!-- /.container-fluid -->
      </section>
      <!-- /.content -->
    </div>
    <CreateUserDrawer 
      :isOpen="createUserDrawerOpen"
      @close="createUserDrawerOpen = false"
      @created="userCreated"
    />
    <UpdateUserDrawer
      :isOpen="!!userToUpdate"
      :user="userToUpdate"
      @close="userToUpdate = null"
      @updated="userUpdated"
    />
    <DeleteUserModal
        :isOpen="!!userToDelete"
        :user="userToDelete"
        @close="userToDelete = null"
        @deleted="userDeleted"
    />
    <UserStationsDrawer
        :isOpen="!!updateStationsFor"
        :user="updateStationsFor"
        @close="updateStationsFor = null"
    />
  </LayoutDefault>
</template>
<script>
import LayoutDefault from "../layout/LayoutAdmin";
import Toast from "../components/Toast";
import Loading from "../components/Loading";
import userService from "@/services/userService";
import Pagination from "../components/Pagination";
import Heading from "../components/Heading";
import PageSizeSelect from "../components/PageSizeSelect";
import SearchForm from "../components/SearchUsersForm";
import CreateUserDrawer from '../components/users/CreateUserDrawer'
import UpdateUserDrawer from '../components/users/UpdateUserDrawer'
import DeleteUserModal from '../components/users/DeleteUserModal'
import UserStationsDrawer from '../components/users/UserStationsDrawer'
import { roles } from '../lib/roles'

const rolesMap = {}
roles.forEach(role => {
  rolesMap[role.id] = role
})

export default {
  name: `Users`,
  data() {
    return {
      // Users Pagination
      page: 1,
      pageSize: 10,
      search: {
        search: '',
        roles: [],
        stations: [],
      },

      pagination: {
        data: [],
      },

      // Drawers
      createUserDrawerOpen: false,
      userToUpdate: null,
      userToDelete: null,
      updateStationsFor: null,

      toastType: "",
      toastActive: false,
      toastMessage: "",
      loading: false,
    };
  },
  async mounted() {
    await this.fetchUsersWithCurrentOptions();
  },
  methods: {
    fetchUsersWithCurrentOptions() {
      return this.fetchUsers({
        page: this.page,
        pageSize: this.pageSize,
        search: this.search.search,
        roles: this.search.roles,
        stations: this.search.stations,
      });
    },

    roleName(id) {
      if (!rolesMap[id]) {
        return id
      }

      return rolesMap[id].label
    },

    async searchChanged(search) {
      this.page = 1
      this.search = search
      this.fetchUsersWithCurrentOptions()
    },

    async fetchUsers(options) {
      this.loading = true;

      try {
        this.pagination = await userService.getAllUsersPaginated({
          ...options,
          sortBy: [
            {
              field: 'lastName',
              dir: 'ASC',
            },
            {
              field: 'name',
              dir: 'ASC',
            },
          ],
        });
      } finally {
        this.loading = false;
      }
    },

    async setPage(page) {
      this.page = page
      this.fetchUsersWithCurrentOptions()
    },

    async setPageSize(size) {
      this.page = 1
      this.pageSize = size 
      this.fetchUsersWithCurrentOptions()
    },

    async userCreated() {
      this.toastType = "success"
      this.toastActive = true
      this.toastMessage = "Usuario creado correctamente"

      this.createUserDrawerOpen = false
      this.fetchUsersWithCurrentOptions()
    },

    async userUpdated() {
      this.toastType = "success"
      this.toastActive = true
      this.toastMessage = "Usuario actualizado correctamente"

      this.userToUpdate = null
      this.fetchUsersWithCurrentOptions()
    },

    async userDeleted() {
      this.toastType = "success"
      this.toastActive = true
      this.toastMessage = "Usuario eliminado correctamente"

      this.userToDelete = null
      this.fetchUsersWithCurrentOptions()
    },
  },
  components: {
    LayoutDefault,
    Toast,
    Loading,
    Pagination,
    Heading,
    PageSizeSelect,
    SearchForm,
    CreateUserDrawer,
    UpdateUserDrawer,
    DeleteUserModal,
    UserStationsDrawer,
  },
};
</script>
