<template>
  <FormSelect
    label="Operador móvil"
    placeholder="Operador móvil"
    :options="options"
    v-bind="$attrs"
  />
</template>

<script>
import FormSelect from './forms/FormSelect'

export default {
  name: 'MobileProviderDropdown',
  data() {
    const options = [
      {id: 'avantel', label: 'Avantel'},
      {id: 'claro', label: 'Claro'},
      {id: 'etb', label: 'Etb'},
      {id: 'exito', label: 'Exito'},
      {id: 'flash', label: 'Flash'},
      {id: 'kalley', label: 'Kalley'},
      {id: 'movistar', label: 'Movistar'},
      {id: 'tigo', label: 'Tigo'},
      {id: 'virgin', label: 'Virgin'},
      {id: 'wings', label: 'Wings'},
      {id: 'wom', label: 'Wom'},
    ]

    const sortedOptions = options
      .sort((a, b) => a.label > b.label)

    return {
      options: [
        ...sortedOptions,
        {id: 'otro', label: 'Otro'},
      ],
    }
  },
  components: {
    FormSelect,
  }
}
</script>
