<template>
  <Drawer
      :isOpen="isOpen"
      title="Crear una estación"
      @close="$emit('close')"
      >
      <StationForm
          ref="stationForm"
          :defaultValues="{}"
          :isLoading="isLoading"
          :serverError="serverError"
          :addDefaultUsers="true"
          @submit="createStation"
          />
  </Drawer>
</template>

<script>
  import StationForm from './StationForm'
  import Drawer from '../Drawer'
  import stationService from '../../services/stationService'

  export default {
    name: 'CreateStationDrawer',
    props: ['isOpen'],
    emits: [
      'close',
      'created',
    ],
    data() {
      return {
        serverError: null,
        isLoading: false,
      }
    },
    methods: {
      async createStation(station, resetForm) {

        this.isLoading = true

        const payload = {
          pbl: station.pbl,
          serviceStation: station.serviceStation,
          pco: station.pco,
          address: station.address,
          phone:station.phone,
          names: station.names,
          emails: station.emails,
          companyName: station.companyName,
          nit: station.nit,
          clientsType:station.clientType,
          stationType: station.stationType,
          users: station.users,
        }

        if (station.location.city) {
          payload.city = station.location.city
        }

        if (station.location.state) {
          payload.department = station.location.state
        }

        try {
          const station = await stationService.create(payload)
          resetForm()
          this.$emit('created', station)
        } catch (e) {
          if (e.name !== 'APIError') {
            throw e
          }

          // Scroll to the top of the form, client need to check the which field has an error.
          this.$refs.stationForm.scrollToTop()

          this.serverError = e
        } finally {
          this.isLoading = false
        }
      }
    },

    components: {
      StationForm,
      Drawer,
    }
  }
</script>
