<template>
  <form
    :class="{'justify-content-end': !justifyStart}"
    class="col-xs-12 col-sm-6 form-inline"
    v-bind="$attrs"
    @submit.prevent="onFormSubmited"
  >
    <div class="form-group">
      <label class="mr-2">Buscar:</label>
      <input
        class="form-control"
        :value="search"
        @change="onSelectChanged"
      />
    </div>
  </form>
</template>

<script>
  export default {
    name: 'SearchForm',
    props: ['search', 'justifyStart'],
    emits: ['change'],
    methods: {
      onChange(value) {
        this.$emit('change', value)
      },

      onSelectChanged(e) {
        this.onChange(e.target.value)
      },

      onFormSubmited() {
        this.onChange(this.search)
      }
    }
  }
</script>
