<template>
  <form
    class="col-xs-12 col-sm-9 form-inline justify-content-end"
    @submit.prevent="onFormSubmited"
  >
    <div class="form-group mr-3">
      <label class="mr-2">Estaciones:</label>
      <div>
        <StationsDropdown
          :modelValue="value.stations"
          @change="stationsChanged"
        />
      </div>
    </div>
    <div class="form-group">
      <label class="mr-2">Rol:</label>
      <div>
        <Multiselect
          :track-by="'id'"
          label="label"
          :modelValue="selectedRoles"
          :options="roles"
          :multiple="true"
          :preserve-search="false"
          :clear-on-select="true"
          :close-on-select="false"
          :searchable="true"
          :allow-empty="true"
          @select="roleAdded"
          @remove="roleRemoved"
        ></Multiselect>
      </div>
    </div>
    <div class="ml-3 form-group">
      <label class="mr-2">Buscar:</label>
      <input
        class="form-control"
        :value="value.search"
        @change="onSearchChanged"
      />
    </div>
  </form>
</template>

<script>
  import Multiselect from './Multiselect'
  import StationsDropdown from './stations/StationsDropdown'
  import { roles } from '../lib/roles'

  export default {
    name: 'SearchUserForm',
    props: ['value'],
    emits: ['change'],

    data() {
      return {
        loadingStations: false,
        stations: [],
        stationsCached: {}, 
        roles,
      }
    },

    computed: {
      selectedRoles() {
        return this.value.roles.map(id => ({
          ...this.roles.find(role => role.id === id),
        }))
      },
    },
    methods: {
      onChange(value) {
        this.$emit('change', value)
      },

      onSearchChanged(e) {
        this.onChange({
          ...this.value,
          search: e.target.value,
        })
      },

      roleAdded(e) {
        this.onChange({
          ...this.value,
          roles: [...this.value.roles, e.id],
        })
      },

      roleRemoved(e) {
        this.onChange({
          ...this.value,
          roles: this.value.roles.filter(role => role !== e.id),
        })
      },

      stationsChanged(e) {
        this.onChange({ ...this.value, stations: e })
      },

      onFormSubmited() {
        this.onChange(this.value)
      }
    },
    components: {
      StationsDropdown,
      Multiselect,
    }
  }
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
