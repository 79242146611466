<template>
  <FormSelect
      label="Tipo de cliente"
      v-bind="$attrs"
      :placeholder="loading ? 'Cargando' : 'Tipo de cliente'"
      :options="topics"
  />
</template>

<script>
import userService from '../../services/userService'
import FormSelect from './FormSelect'

export default {
  name: 'ClientsTypeDropdown',
  data() {
    return {
      loading: true,
      topics: [],
    }
  },
  async mounted() {
    try {
      const request = await userService.getUserTopics()
      this.topics = request.response.map(topic => {
        return {
          id: topic.id,
          label: topic.name,
        }
      })
    } finally {
      this.loading = false
    }
  },
  components: {
    FormSelect,
  }
}
</script>
