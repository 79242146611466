<template>
  <Modal
    title="Eliminar usuario"
    :isOpen="!!user"
    @close="closeRequest"
  >
    <ModalBody>
      ¿Estás seguro de eliminar este usuario?
    </ModalBody>
    <ModalFooter>
      <Button
        type="button"
        status="default"
        :isLoading="isLoading"
        @click="closeRequest"
        >
        Cancelar
      </Button>
      <Button
        type="button"
        status="danger"
        :isLoading="isLoading"
        @click="deleteClicked"
        >
        Eliminar
      </Button>
    </ModalFooter>
  </Modal>
</template>

<script>
  import Modal from  '../Modal'
  import ModalBody from  '../ModalBody'
  import ModalFooter from  '../ModalFooter'
  import Button from  '../Button'
  import userService from '../../services/userService'

  export default {
    name: 'DeleteUserModal',
    props: ['user'],
    emits: ['deleted', 'close'],
    data() {
      return {
        isLoading: false,
      }
    },
    methods: {
      closeRequest() {
        this.$emit('close')
      },
      async deleteClicked() {
        this.loading = true
        try {
          await userService.deleteUser(this.user.id)
          this.$emit('deleted', this.user)
        } finally {
          this.isLoading = false
        }
      },
    },
    components: {
      Modal,
      ModalBody,
      ModalFooter,
      Button,
    }
  }
</script>
