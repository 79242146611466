<template>
  <form
    @submit.prevent="onSubmit"
    novalidate
  >
    <fieldset>
      <legend ref="header">Datos básicos</legend>
      <FormInput
          label="Número de documento"
          v-model="cc"
          placeholder="1020787878"
          :error="errors.cc"
          />
      <FormInput
          required
          label="Nombres"
          v-model="name"
          placeholder="José de la Concordia"
          :error="errors.name"
          />
      <FormInput
          required
          label="Apellidos"
          v-model="lastName"
          placeholder="Márquez"
          :error="errors.lastName"
          /> 

      <RoleDropdown
          required
          label="Rol"
          v-model="role"
          :error="errors.role"
      />

      <div class="my-2">
        <BooleanCheckbox
            label="¿Es un usuario activo en la aplicación?"
            v-model="isActive"
            :error="errors.isActive"
        />
      </div>
    </fieldset>

    <fieldset>
      <legend>Datos de contácto</legend>

      <FormInput
          label="Correo electrónico"
          v-model="email"
          placeholder="jose.de.la.concordia@marquez.com"
          :error="errors.email"
          />

      <FormInput
          label="Número de teléfono"
          v-model="phone"
          placeholder="3203797979"
          :error="errors.phone"
          />
    </fieldset>

    <fieldset>
      <legend>Datos de demográficos</legend>

      <DatePicker
        label="Fecha de nacimiento"
        v-model="birthDay"
        placeholder="Por ejemplo: 12/12/1993"
        :flow="datepickerFlow"
        :enable-time-picker="false"
      ></DatePicker>

      <GenderDropdown
          label="Género"
          v-model="gender"
          :error="errors.gender"
          />

      <MobileProviderDropdown
          label="Operador móvil"
          v-model="mobileOperator"
          :error="errors.mobileOperator"
          />

      <MaritalStatus
          label="Estado civíl"
          v-model="civilStatus"
          :error="errors.civilStatus"
          /> 

    </fieldset>

    <fieldset>
      <legend>Datos laborales</legend>

      <FormInput
          label="Cargo"
          v-model="position"
          :error="errors.position"
          />

      <FormInput
          label="Personas a cargo"
          v-model="dependents"
          :error="errors.dependents"
          />

      <WorkShiftDropdown
          label="Turno de trabajo"
          v-model="workShift"
          :error="errors.workShift"
          />

      <WorkLevelDropdown
          label="Nivel de tráfico"
          v-model="traffic"
          :error="errors.traffic"
          />
    </fieldset>

    <Button
        type="submit"
        status="info"
        :isLoading="isLoading"
        >
        Guardar
    </Button>
  </form>
</template>

<script>
  import * as yup from 'yup'
  import { es } from 'yup-locales';
  import DatePicker from '../FormDatePicker'
  import Button from '../Button'
  import FormInput from '../forms/FormInput'
  import {roles} from '../../lib/roles'
  import {getErrors} from '../../lib/forms'
  import RoleDropdown from './RoleDropdown'
  import BooleanCheckbox from '../BooleanCheckbox'
  import GenderDropdown from '../GenderDropdown'
  import MobileProviderDropdown from '../MobileProviderDropdown'
  import MaritalStatus from '../MaritalStatus'
  import WorkShiftDropdown from '../WorkShiftDropdown'
  import WorkLevelDropdown from '../WorkLevelDropdown'

  yup.setLocale(es)

  const schema = yup.object({
    cc: yup.number().nullable(true),
    name: yup.string().required(),
    lastName: yup.string().required(),
    role: yup.mixed()
      .oneOf(roles.map(role => role.id))
      .required(),

    isActive: yup.boolean(),

    email: yup.string()
      .email()
      .required(),

    phone: yup.string()
      .matches(/^[0-9]{10,12}/, {
        message: 'El número de teléfono no es valido',
      })
      .nullable(true),

    birthDay: yup.date().nullable(true),

    gender: yup.mixed()
      .oneOf(['male', 'female'])
      .nullable(true),

    mobileOperator: yup.string().nullable(true),
    civilStatus: yup.string().nullable(true),
    position: yup.string().nullable(true),
    dependents: yup.string()
      .matches(/^[0-9]{1,2}/, {
        message: 'El número no es valido',
      })
      .nullable(true),
    workShift: yup.string().nullable(true),
    traffic: yup.string().nullable(true),
  })

  export default {
    name: 'CreateUserForm',

    props: [
      'isLoading',
      'serverError',
      'defaultValues',
    ],

    expose: ['scrollToTop'],

    emits: ['submit'],

    watch: {
      serverError(err) {
        err.fields.forEach(err => {
          this.errors[err.field] = err
        })
      },
    },

    data() {
      return {
        errors: {},
        datepickerFlow: [
          'year',
          'month',
          'calendar',
        ],
        ...this.initialState(),
      };
    },

    methods: {
      scrollToTop() {
        this.$refs.header.scrollIntoView()
      },
      locationChanged(location) {
        this.location = location
      },
      clientTypeChanged(clientType) {
        this.clientType = clientType
      },
      userTypeChanged(userType) {
        this.userType = userType
      },
      initialState() {
        const df = this.defaultValues || {}

        return {
          cc: df.cc,
          name: df.name,
          lastName: df.lastName,
          email: df.email,
          phone: df.phone,
          gender: df.gender,
          birthDay: df.birthDay,
          mobileOperator: df.mobileOperator,
          civilStatus: df.civilStatus,
          position: df.position,
          role: df.role,
          dependents: df.dependents,
          workShift: df.workShift,
          traffic: df.traffic,
          isActive: df.isActive,
        }
      },

      async onSubmit() {
        const payload = {
          cc: this.cc,
          name: this.name,
          lastName: this.lastName,
          email: this.email,
          phone: this.phone,
          gender: this.gender,
          birthDay: this.birthDay,
          mobileOperator: this.mobileOperator,
          civilStatus: this.civilStatus,
          role: this.role,
          position: this.position,
          dependents: this.dependents,
          workShift: this.workShift,
          traffic: this.traffic,
        }

        try {
          this.errors = {}

          const user = await schema.validate(payload, {
            abortEarly: false,
            stripUnknown: true
          })

          this.$emit('submit', user, () => this.reset())
        } catch (e) {
          this.errors = getErrors(e)
        }
      },

      reset() {
        Object.assign(this.$data, this.initialState());
      },
    },

    components: {
      FormInput,
      Button,
      RoleDropdown,
      BooleanCheckbox,
      DatePicker,
      GenderDropdown,
      MobileProviderDropdown,
      MaritalStatus,
      WorkShiftDropdown,
      WorkLevelDropdown,
    }
  }
</script>

<style>
.dp__action_button {
  height: auto;
}
</style>
