import * as yup from 'yup'

/**
 * Get the errors from the 'yup' errors
 * or throw the exception, this is the easiest
 * way to get the form errors.
 */
export function getErrors(e) {
  if (!(e instanceof yup.ValidationError)) {
    throw e
  }

  const validation = {};
  e.inner.forEach(err => {
    validation[err.path] = err
  })

  return validation
}
