<template>
  <Modal
    title="Eliminar estación"
    :isOpen="!!station"
    @close="closeRequest"
  >
    <ModalBody>
      ¿Estás seguro de eliminar esta estación?
    </ModalBody>
    <ModalFooter>
      <Button
        type="button"
        status="default"
        :isLoading="isLoading"
        @click="closeRequest"
        >
        Cancelar
      </Button>
      <Button
        type="button"
        status="danger"
        :isLoading="isLoading"
        @click="deleteClicked"
        >
        Eliminar
      </Button>
    </ModalFooter>
  </Modal>
</template>

<script>
  import Modal from  '../Modal'
  import ModalBody from  '../ModalBody'
  import ModalFooter from  '../ModalFooter'
  import Button from  '../Button'
  import stationService from '../../services/stationService'

  export default {
    name: 'DeleteStationModal',
    props: ['station'],
    emits: ['deleted', 'close'],
    data() {
      return {
        isLoading: false,
      }
    },
    methods: {
      closeRequest() {
        this.$emit('close')
      },
      async deleteClicked() {
        this.loading = true
        try {
          await stationService.remove(this.station.id)
          this.$emit('deleted', this.station)
        } finally {
          this.isLoading = false
        }
      },
    },
    components: {
      Modal,
      ModalBody,
      ModalFooter,
      Button,
    }
  }
</script>
