<template>
  <Multiselect
    v-bind="$props"

    track-by="id"
    label="service_station"
    :modelValue="selectedStations"
    :isLoading="loadingStations"
    :options="stations"
    :multiple="true"
    :preserve-search="false"
    :clear-on-select="true"
    :close-on-select="false"
    :searchable="true"
    :allow-empty="true"
    @search-change="stationSearchChanged"
    @select="stationAdded"
    @remove="stationRemoved"
  ></Multiselect>
</template>

<script>
  import Multiselect from '../Multiselect'
  import stationService from '../../services/stationService'

  export default {
    name: 'StationsDropdown',
    props: ['modelValue', 'initialStations'],
    emits: ['change'],

    data() {
      const stationsCached = {}
      const initialStations = this.initialStations || [];
      initialStations.forEach((station) => {
        stationsCached[station.id] = station
      })

      return {
        loadingStations: false,
        stations: [],
        stationsCached, 
      }
    },

    computed: {
      selectedStations() {
        return this.modelValue.map(id => ({
          ...this.stationsCached[id],
        }))
      },
    },

    methods: {
      onChange(v) {
        this.$emit('change', v)
      },

      async stationSearchChanged(search) {
        this.loadingStations = true

        try {
          const pagination = await stationService.getPaginatedStationsWithoutUsers({
            pageSize: 20,
            search,
          })
          this.stations = pagination.data
        } finally {
          this.loadingStations = false
        }
      },

      stationAdded(e) {
        this.stationsCached[e.id] = e

        this.onChange([...this.modelValue, e.id])
      },

      stationRemoved(e) {
        this.onChange(this.modelValue.filter(station => station !== e.id))
      },
    },
    
    async mounted() {
      await this.stationSearchChanged()
    },

    components: {
      Multiselect,
    }
  }
</script>
