<template>
  <Drawer
      :isOpen="isOpen"
      title="Actualizar un usuario"
      @close="$emit('close')"
      >
      <ul class="nav nav-pills mb-3 border-bottom">
        <a
          class="nav-link"
          :class="{active: visible === 'data'}"
          @click="visible = 'data'"
        >
          Actualizar datos
        </a>
        <a
          class="nav-link"
          :class="{active: visible === 'password'}"
          @click="visible = 'password'"
        >
          Actualizar contraseña
        </a>
      </ul>
      <UserForm
          ref="userForm"
          :key="user ? user.id : null"
          v-if="visible === 'data'"
          :defaultValues="formValues"
          :isLoading="isLoading"
          :serverError="serverError"
          @submit="updateUser"
          />
      <UpdatePasswordForm
          :key="user ? user.id : null"
          v-if="visible === 'password'"
          @submit="updatePassword"
      />
  </Drawer>
</template>

<script>
  import Drawer from '../Drawer'
  import UserForm from './UserForm'
  import UpdatePasswordForm from './UpdatePasswordForm'
  import userService from '../../services/userService'

  export default {
    name: 'UpdateUserDrawer',
    props: [
      'isOpen',
      'user',
    ],
    emits: [
      'close',
      'created',
    ],
    watch: {
      user(newUser) {
        if (!newUser) {
          this.formValues = {}
          return
        }

        this.formValues = {
          isActive: !!newUser.isActive,
          cc: newUser.cc,
          name: newUser.name,
          lastName: newUser.lastName,
          role: newUser.role,
          email: newUser.email,
          phone: newUser.phone,
          birthDay: newUser.birth_day
            ? new Date(newUser.birth_day)
            : null,
          gender: newUser.gender,
          mobileOperator: newUser.mobile_operator,
          position: newUser.position,
          dependents: newUser.dependents,
          workShift: newUser.work_shift,
          traffic: newUser.traffic_eds,
        }
      }
    },
    data() {
      return {
        serverError: null,
        isLoading: false,
        // Available options: 'data', 'password'
        visible: 'data',
        formValues: {},
      }
    },
    methods: {
      async updateUser(data, resetForm) {

        this.isLoading = true

        try {
          const user = await userService.updateUser(this.user.id, data)
          resetForm()
          this.$emit('updated', user)
        } catch (e) {
          if (e.name !== 'APIError') {
            throw e
          }

          // Scroll to the top of the form, client need to check the which
          // field has an error.
          this.$refs.userForm.scrollToTop()

          this.serverError = e
        } finally {
          this.isLoading = false
        }
      },

      async updatePassword(data, resetForm) {
        this.isLoading = true

        try {
          await userService.updatePassword(this.user.id, data.password);
          resetForm()
          this.$emit('updated', this.user)
          this.visible = 'data'
        } finally {
          this.isLoading = false
        }
      },
    },

    components: {
      UserForm,
      Drawer,
      UpdatePasswordForm,
    }
  }
</script>
