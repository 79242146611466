<template>
  <LayoutDefault>
  <!-- Content Wrapper. Contains page content -->
  <div class="content-wrapper">
    <Toast
        :message="toastMessage"
        v-model:active="toastActive"
        :type="toastType"
        />

    <Loading :active="loading" />
    <!-- Content Header (Page header) -->
    <div class="content-header mt-2">
      <div class="container-fluid">
        <Heading title="Estaciones">
          <button
              type="button"
              class="btn btn-default btn-default-invert float-right ml-2 mb-2 mb-md-0"
              @click="createStationDrawerOpen = true"
              >
              Crear
          </button>
        </Heading>
        <div class="row">
          <PageSizeSelect
              class="col-xs-12 col-sm-6"
              :size="pageSize"
              @change="setPageSize"
              />

          <SearchForm
              :search="search"
              @change="searchChanged"
              />
          <!-- /.col -->
        </div>
        <!-- /.row -->
      </div>
      <!-- /.container-fluid -->
    </div>
    <!-- /.content-header -->

    <!-- Main content -->
    <section class="content">
      <div class="container-fluid">
        <div class="card card--table">

            <!-- /.card-header -->
            <div class="card-body">
              <table id="stations" class="table table-bordered table-striped">
                <thead>
                  <tr>
                    <th>PBL</th>
                    <th>Nombre</th>
                    <th>Ciudad</th>
                    <th>Emails</th>
                    <th>Opciones</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="station of pagination.data" :key="station.id">
                    <td>{{ station.pbl }}</td>
                    <td>{{ station.service_station }}</td>
                    <td class="low-contrast">
                      {{ station.city }} <span>{{ station.department }}</span>
                    </td>
                    <td class="low-contrast">{{ station.emails }}</td>
                    <td>
                      <div>
                        <button
                          class="unstyled ml-3"
                          @click="updateStation(station)"
                          >
                          <i class="fas fa-pen"></i>
                        </button>
                        <button
                          class="unstyled"
                          @click="deleteStationRequest(station)"
                          >
                          <i class="fas fa-trash"></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>

              <Pagination
                  :pagination="pagination"
                  @set-page="setPage"
                  />
            </div>
        </div>
      </div>
    </section>
    <CreateStationDrawer
        :isOpen="createStationDrawerOpen"
        @close="createStationDrawerOpen = false"
        @created="stationCreated"
        />
    <UpdateStationDrawer
        :isOpen="!!stationToUpdate"
        :station="stationToUpdate"
        @close="stationToUpdate = null"
        @updated="stationUpdated"
        />
    <DeleteStationModal
        :isOpen="!!stationToDelete"
        :station="stationToDelete"
        @close="stationToDelete = null"
        @deleted="stationDeleted"
    />
  </div>
  </LayoutDefault>
</template>

<script>
  import config from "@/config/config";
  import LayoutDefault from "../layout/LayoutAdmin";
  import Toast from "../components/Toast";
  import Pagination from "../components/Pagination";
  import PageSizeSelect from "../components/PageSizeSelect";
  import SearchForm from '../components/SearchForm';
  import Heading from '../components/Heading';
  import Loading from "../components/Loading";
  import stationService from "@/services/stationService";
  import CreateStationDrawer from "../components/stations/CreateStationDrawer";
  import UpdateStationDrawer from "../components/stations/UpdateStationDrawer";
  import DeleteStationModal from "../components/stations/DeleteStationModal";

  export default {
    name: 'Stations',

    data() {
      return {
        // Drawer
        createStationDrawerOpen: false,
        stationToUpdate: null,
        stationToDelete: null,

        // Stations Pagination
        page: 1,
        pageSize: 10,
        search: '',

        // Stations data
        pagination: {
          data: [],
        },

        // Toast
        toastType: "",
        toastActive: false,
        toastMessage: "",

        // Status
        loading: false,

        // config
        config: config.getConfig(),
      };
    },

    async mounted() {
      await this.fetchStationsWithCurrentOptions();
    },

    methods: {
      stationCreated() {
        this.toastMessage = 'Estación creada correctamente'
        this.toastActive = true
        this.toastType = 'success'
        this.createStationDrawerOpen = false

        this.fetchStationsWithCurrentOptions()
      },

      stationUpdated() {
        this.toastMessage = 'Estación actualizada correctamente'
        this.toastActive = true
        this.toastType = 'success'
        this.stationToUpdate = null

        this.fetchStationsWithCurrentOptions()
      },

      stationDeleted() {
        this.toastMessage = 'Estación eliminada correctamente'
        this.toastActive = true
        this.toastType = 'success'
        this.stationToDelete = null

        this.fetchStationsWithCurrentOptions()
      },

      deleteStationRequest(station) {
        this.stationToDelete = station
      },

      updateStation(station) {
        this.stationToUpdate = station
      },

      fetchStationsWithCurrentOptions() {
        return this.fetchStations({
          page: this.page,
          pageSize: this.pageSize,
          search: this.search,
        });
      },

      async searchChanged(search) {
        this.page = 1
        this.search = search
        this.fetchStationsWithCurrentOptions()
      },

      async setPageSize(size) {
        this.page = 1
        this.pageSize = size 
        this.fetchStationsWithCurrentOptions()
      },

      async setPage(page) {
        this.page = page
        this.fetchStationsWithCurrentOptions()
      },

      async fetchStations(options) {
        this.loading = true;

        try {
          this.pagination = await stationService.getPaginatedStationsWithoutUsers(options);
        } finally {
          this.loading = false;
        }
      },
    },

    components: {
      LayoutDefault,
      Toast,
      Loading,
      Pagination,
      PageSizeSelect,
      SearchForm,
      Heading,
      CreateStationDrawer,
      UpdateStationDrawer,
      DeleteStationModal,
    },
  };
</script>

<style lang="scss" scoped>
#stations  {
  td {
    &:first-child {
      width: 10%;
    }
    &:nth-child(2) {
      width: 40%;
    }
    &:nth-child(3) {
      text-transform: uppercase;
    }
    &:nth-child(3), td:nth-child(4) {
      width: 15%;
    }
    &:last-child {
      width: 10%;
    }
    span {
      font-size: 0.8rem;
    }
  }
}
</style>
