<template>
  <Drawer
      :isOpen="isOpen"
      title="Crear un usuario"
      @close="$emit('close')"
      >
      <UserForm
          ref="userForm"
          :defaultValues="defaultValues"
          :isLoading="isLoading"
          :serverError="serverError"
          @submit="createUser"
          />
  </Drawer>
</template>

<script>
  import UserForm from './UserForm'
  import Drawer from '../Drawer'
  import userService from '../../services/userService'

  export default {
    name: 'CreateUserDrawer',
    props: ['isOpen'],
    emits: [
      'close',
      'created',
    ],
    data() {
      return {
        serverError: null,
        isLoading: false,
        defaultValues: {
          isActive: true,
          // cc: '102078787878',
          // name: 'John',
          // lastName: 'Doe',
          // role: 'admin',
          // email: 'john+doe@lapix.com.co',
          // phone: '3203797979',
          // birthDay: '1999-12-12',
          // gender: 'male',
          // mobileOperator: 'claro',
          // position: 'Tech Lead',
          // dependents: '10',
          // workShift: 'am',
          // traffic: 'alto',
        }
      }
    },
    methods: {
      async createUser(data, resetForm) {

        this.isLoading = true

        try {
          const user = await userService.createUser(data)
          resetForm()
          this.$emit('created', user)
        } catch (e) {
          if (e.name !== 'APIError') {
            throw e
          }

          // Scroll to the top of the form, client need to check the which field has an error.
          this.$refs.userForm.scrollToTop()

          this.serverError = e
        } finally {
          this.isLoading = false
        }
      }
    },

    components: {
      UserForm,
      Drawer,
    }
  }
</script>
