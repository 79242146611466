<template>
  <form
    @submit.prevent="onSubmit"
    novalidate
  >
    <fieldset>
      <legend ref="header">Datos básicos</legend>
      <FormInput
          required
          label="PBL"
          v-model="pbl"
          :error="errors.pbl"
          />
      <FormInput
          required
          label="Nombre"
          v-model="serviceStation"
          :error="errors.serviceStation"
          />
      <LocationDropdown :value="location" @change="locationChanged" />
      <FormInput
          label="Dirección"
          v-model="address"
          :error="errors.address"
          />
    </fieldset>

    <fieldset>
      <legend>Datos legales</legend>
      <FormInput
          label="Razón social"
          v-model="companyName"
          :error="errors.companyName"
          />
      <FormInput
          label="NIT"
          v-model="nit"
          :error="errors.nit"
          />
      <FormInput
          label="PCO"
          v-model="pco"
          :error="errors.pco"
          />
    </fieldset>

    <fieldset>
      <legend>Configuración</legend>
      <ClientsTypeDropdown
        :value="clientType"
        @change="clientTypeChanged"
      />
      <StationTypesDropdown
        :value="stationType"
        @change="stationTypeChanged"
      />
    </fieldset>

    <fieldset>
      <legend>Contácto</legend>
      <FormInput
          label="Nombre"
          v-model="names"
          :error="errors.names"
          />
      <FormInput
          label="Correo electrónico"
          v-model="emails"
          :error="errors.emails"
          />
      <FormInput
          label="Teléfono"
          v-model="phone"
          :error="errors.phone"
          />
    </fieldset>

    <fieldset v-if="addDefaultUsers">
      <legend>Gerentes</legend>
      <div class="form-group">
        <label class="mr-2">Gerente de área y gerente regional:</label>
        <Multiselect
            :track-by="'id'"
            label="label"
            :multiple="true"
            v-model="users"
            :options="coaches"
            />
      </div>
    </fieldset>

    <Button
        type="submit"
        status="info"
        :isLoading="isLoading"
        >
        Guardar
    </Button>
  </form>
</template>

<script>
  import * as yup from 'yup'
  import { es } from 'yup-locales';
  import Button from '../Button'
  import FormInput from '../forms/FormInput'
  import Multiselect from '../Multiselect'
  import LocationDropdown from '../forms/LocationDropdown'
  import StationTypesDropdown from '../forms/StationTypesDropdown'
  import ClientsTypeDropdown from '../forms/ClientsTypeDropdown'
  import userService from "@/services/userService"

  yup.setLocale(es)

  const schema = yup.object({
    pbl: yup.number().required(),
    serviceStation: yup.string().required(),
    address: yup.string(),
    companyName: yup.string(),
    nit: yup.string().nullable(true).transform(nit => {
      if (nit) {
        return nit.toString()
      }

      return nit
    }),
    pco: yup.string(),
    names: yup.string(),
    emails: yup.string(),
    phone: yup.string(),
    clientType: yup.string(),
    stationType: yup.string(),
    location: yup.object({
      state: yup.string(),
      city: yup.string(),
    }),
    users: yup.array().of(yup.number())
  })

  export default {
    name: 'CreateStationForm',

    props: [
      'isLoading',
      'serverError',
      'defaultValues',
      'addDefaultUsers',
    ],

    expose: ['scrollToTop'],

    emits: ['submit'],

    watch: {
      serverError(err) {
        err.fields.forEach(err => {
          this.errors[err.field] = err
        })
      },
    },

    data() {
      return {
        coaches: [],
        ...this.initialState(),
      };
    },

    mounted() {
      this.fetchCoaches()
    },

    methods: {
      scrollToTop() {
        this.$refs.header.scrollIntoView()
      },
      locationChanged(location) {
        this.location = location
      },
      clientTypeChanged(clientType) {
        this.clientType = clientType
      },
      stationTypeChanged(stationType) {
        this.stationType = stationType
      },

      async fetchCoaches() {
        const response = await userService.getAllUsersPaginated({
          page: 1,
          pageSize: 100,
          roles: ['manager'],
          sortBy: [{field: 'lastName'}, {field: 'name'}],
        })
        this.coaches = response.data.map((user) => {
          return {id: user.id, label: `${user.lastName} ${user.name}`}
        })
      },

      initialState() {
        const df = this.defaultValues || {}

        return {
          errors: {},

          pbl: df.pbl,
          serviceStation: df.serviceStation,
          address: df.address,
          companyName: df.companyName,
          nit: df.nit,
          pco: df.pco,
          names: df.names,
          emails: df.emails,
          phone: df.phone,
          clientType: df.clientType,
          stationType: df.stationType,
          location: df.location || {},
          users: [],
        }
      },

      async onSubmit() {
        const payload = {
          pbl: this.pbl,
          serviceStation: this.serviceStation,
          address: this.address,
          companyName: this.companyName,
          nit: this.nit,
          pco: this.pco,
          names: this.names,
          emails: this.emails,
          phone: this.phone,
          clientType: this.clientType,
          stationType: this.stationType,
          location: this.location
          ? {
            state: this.location.state,
            city: this.location.city,
          }
          : null,
        }

        // Add the users to the request if needed.
        if (this.$props.addDefaultUsers) {
          payload.users = this.users.map(user => user.id);
        }

        try {
          this.errors = {}

          const station = await schema.validate(payload, {
            abortEarly: false,
            stripUnknown: true
          })

          this.$emit('submit', station, () => {
            Object.assign(this.$data, this.initialState());
          })
        } catch (e) {
          if (e instanceof yup.ValidationError) {
            const validation = {};
            e.inner.forEach(err => {
              validation[err.path] = err
            })

            this.errors = validation

            return
          }

          throw e
        }
      },
    },

    components: {
      FormInput,
      Multiselect,
      LocationDropdown,
      ClientsTypeDropdown,
      StationTypesDropdown,
      Button,
    }
  }
</script>
