<template>
  <div class="modal-footer">
    <slot />
  </div>
</template>

<script>
  export default {
    name: 'ModalFooter',
  }
</script>
