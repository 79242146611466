<template>
  <VueDatePicker
    locale="es"
    select-text="Seleccionar"
    cancel-text="Cancelar"
    v-bind="$attrs"
  ></VueDatePicker>
</template>

<script>
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'

export default {
  name: 'DatePicker',
  components: {
    VueDatePicker,
  }
}
</script>
