<template>
  <FormSelect
    label="Estado civil"
    placeholder="Estado civil"
    :options="options"
    v-bind="$attrs"
  />
</template>

<script>
import FormSelect from './forms/FormSelect'

export default {
  name: 'MaritalStatus',
  data() {
    const options = [
      {id: 'casado', label: 'Casado'},
      {id: 'comprometido', label: 'Comprometido'},
      {id: 'divorciado', label: 'Divorciado'},
      {id: 'relacion', label: 'Relación'},
      {id: 'soltero', label: 'Soltero'},
      {id: 'union', label: 'Union libre'},
      {id: 'viudo', label: 'Viudo'},
    ]

    const sortedOptions = options
      .sort((a, b) => a.label > b.label)

    return {
      options: sortedOptions,
    }
  },
  components: {
    FormSelect,
  }
}
</script>
