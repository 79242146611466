<template>
  <div
      class="modal fade"
      :class="{show: isOpen}"
      tabindex="-1"
      role="dialog"
      :style="{display: isOpen ? 'block' : 'none'}"
      ref="overlay"
      @click="onverlayClicked"
      >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              {{title}}
            </h5>

            <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Cerrar"
                @click="closeRequest"
                >
                <span aria-hidden="true">×</span>
            </button>
          </div>
          <slot />
        </div>
      </div>
  </div> 
  <div
    v-if="isOpen"
    class="modal-backdrop fade show"
    :class="{show: isOpen}"
  />
</template>

<script>
  export default {
    name: 'Modal',
    props: ['title', 'isOpen'],
    emits: ['close'],
    methods: {
      closeRequest() {
        this.$emit('close')
      },
      onverlayClicked(event) {
        if (event.target === this.$refs.overlay) {
          this.closeRequest()
        }
      },
    },
    components: {}
  }
</script>
