<template>
  <button
    class="btn"
    :class="[{isLoading}, `btn${outline ? '-outline' : ''}-${status || 'info'}`]"
    :disabled="isLoading"
    v-bind="$attrs"
  >
    <i v-if="isLoading" class="fas fa-spinner mr-1"></i>
    <slot />
  </button>
</template>

<script>
export default {
  name: 'Button',
  props: ['isLoading', 'status', 'outline'],
  components: {
  },
}
</script>

<style>

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.btn.isLoading i {
  animation: rotating 2s linear infinite;
}
</style>

