<template>
  <FormSelect
    label="Turno de trabajo"
    placeholder="Turno de trabajo"
    :options="options"
    v-bind="$attrs"
  />
</template>

<script>
import FormSelect from './forms/FormSelect'

export default {
  name: 'WorkShiftDropdown',
  data() {
    const options = [
      {id: 'am', label: 'AM'},
      {id: 'pm', label: 'PM'},
    ]

    return { options }
  },
  components: {
    FormSelect,
  }
}
</script>
